const GiphyWrapper = ({ id, href }) => (
  <div className="header-gif">
    <iframe
      title="giphy"
      src={`https://giphy.com/embed/${id}`}
      width="500"
      height="269"
      frameBorder="0"
      className="giphy-embed"
      allowFullScreen
    />
    <p>
      <a href={href}>via GIPHY</a>
    </p>
  </div>
)

export default GiphyWrapper
