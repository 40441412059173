import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import PageContent from 'components/PageContent'
import TitleWithBack from 'components/TitleWithBack'
import DrawingForm from 'components/Forms/DrawingForm'
import DrawingService from 'services/DrawingService'
import openNotification from 'utils/notification'

const DrawingEditPage = () => {
  const { drawingId } = useParams()
  const history = useHistory()

  const [drawing, setDrawing] = useState(null)

  useEffect(() => {
    DrawingService.get(drawingId).then(drawing => {
      setDrawing(drawing)
    })
  }, [drawingId])

  const onSubmit = request => {
    setDrawing(null)
    DrawingService.update(drawingId, request['name'], request['description'])
      .then(() => {
        openNotification(
          'success',
          'Geändert!',
          'Die Wichtelei wurde erfolgreich geändert!'
        )
      })
      .then(() => history.push('/admin'))
  }

  return (
    <PageContent
      title={<TitleWithBack title="Wichtelung bearbeiten" />}
      size="small"
      loading={!drawing}
    >
      {drawing && (
        <DrawingForm
          onSubmit={onSubmit}
          valueName={drawing.name}
          valueDescription={drawing.description}
        />
      )}
    </PageContent>
  )
}

export default DrawingEditPage
