import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import PageContent from 'components/PageContent'
import DrawingsTable from 'components/Tables/DrawingsTable'
import DrawingService from 'services/DrawingService'

const DrawingsAdminPage = () => {
  const history = useHistory()

  const [drawings, setDrawings] = useState(null)

  useEffect(() => {
    loadDrawings()
  }, [])

  const loadDrawings = () => {
    setDrawings(null)
    DrawingService.getAll().then(drawings => setDrawings(drawings))
  }

  const openCreate = () => history.push('/admin/create')

  const openEdit = id => history.push(`/admin/drawing/${id}`)

  const openParticipants = id => history.push(`/admin/participants/${id}`)

  const startDrawing = id =>
    DrawingService.start(id).then(() => loadDrawings())

  const handleDelete = id =>
    DrawingService.delete(id).then(() => loadDrawings())

  return (
    drawings && (
      <PageContent
        title="Wichtel Administration"
        extra={<CreateButton disabled={!drawings} handleCreate={openCreate} />}
        size="full"
        loading={!drawings}
      >
        <DrawingsTable
          drawings={drawings}
          handleDelete={handleDelete}
          handleEdit={openEdit}
          handleParticipants={openParticipants}
          handleStart={startDrawing}
        />
      </PageContent>
    )
  )
}

const CreateButton = ({ disabled, handleCreate }) => (
  <Button
    type="primary"
    disabled={disabled}
    icon={<PlusOutlined />}
    onClick={handleCreate}
  >
    Erstellen
  </Button>
)

export default DrawingsAdminPage
