import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import PageContent from 'components/PageContent'
import TitleWithBack from 'components/TitleWithBack'
import ParticipantsSelection from 'components/ParticipantsSelection'
import ParticipantForm from 'components/Forms/ParticipantForm'
import ParticipantService from 'services/ParticipantService'
import openNotification from 'utils/notification'

const ParticipantEditPage = () => {
  const { participantId } = useParams()
  const history = useHistory()

  const [participant, setParticipant] = useState(null)
  const [otherParticipants, setOtherParticipants] = useState(null)
  const [notPartnerId, setNotPartnerId] = useState(undefined)

  useEffect(() => {
    ParticipantService.get(participantId)
      .then(participant => {
        setParticipant(participant)
        setNotPartnerId(participant.notPartnerId)
        return participant.drawingId
      })
      .then(drawingId => {
        ParticipantService.getAllForList(drawingId).then(participants => {
          if (participants && participants.length > 0) {
            const others = participants.filter(p => p.id !== participantId)
            setOtherParticipants(others)
          }
        })
      })
  }, [participantId])

  const onSubmit = request => {
    ParticipantService.update(
      participantId,
      request['name'],
      request['email'],
      request['hint'],
      notPartnerId
    )
      .then(() => {
        openNotification(
          'success',
          'Geändert!',
          'Die Teilnehmer-Daten wurde erfolgreich geändert!'
        )
      })
      .then(() => history.push(`/admin/participants/${participant.drawingId}`))
  }

  return (
    <PageContent
      title={<TitleWithBack title="Teilnehmer bearbeiten" />}
      size="small"
      loading={!participant}
    >
      <ParticipantForm
        disabled={!participant}
        onSubmit={onSubmit}
        participant={participant}
        otherParticipants={otherParticipants}
      >
        <ParticipantsSelection
          disabled={!otherParticipants}
          participants={otherParticipants}
          selected={notPartnerId}
          onSelect={id => setNotPartnerId(id)}
          onClear={() =>  setNotPartnerId(null)}
        />
      </ParticipantForm>
    </PageContent>
  )
}

export default ParticipantEditPage
