import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import PageContent from 'components/PageContent'
import TitleWithBack from 'components/TitleWithBack'
import ParticipantsTable from 'components/Tables/ParticipantsTable'
import ParticipantService from 'services/ParticipantService'

function ParticipantsAdminPage() {
  const { drawingId } = useParams()
  const history = useHistory()

  const [participants, setParticipants] = useState(null)

  const loadParticipants = useCallback(() => {
    setParticipants(null)
    ParticipantService.getAllForDrawing(drawingId).then(participants =>
      setParticipants(participants)
    )
  }, [drawingId])

  useEffect(() => {
    loadParticipants()
  }, [loadParticipants])

  const openEdit = id => history.push(`/admin/participant/${id}`)

  const handleDelete = id => {
    ParticipantService.delete(id).then(() => loadParticipants())
  }

  return (
    participants && (
      <PageContent
        title={<TitleWithBack title="Teilnehmer" path="/admin" />}
        size="full"
        loading={!participants}
      >
        <ParticipantsTable
          participants={participants}
          handleDelete={handleDelete}
          handleEdit={openEdit}
        />
      </PageContent>
    )
  )
}

export default ParticipantsAdminPage
