import { Form, Input, Button } from 'antd'

const LoginForm = ({ disabled, onSubmit }) => (
  <Form name="basic" layout="vertical" onFinish={onSubmit}>
    <Form.Item
      label="Benutzername:"
      name="username"
      rules={[
        { required: true, message: 'Please gebe dein Benutzername ein!' }
      ]}
    >
      <Input disabled={disabled} />
    </Form.Item>

    <Form.Item
      label="Passwort:"
      name="password"
      rules={[{ required: true, message: 'Bitte gebe dein Passwort ein!' }]}
    >
      <Input.Password disabled={disabled} />
    </Form.Item>

    <Form.Item className="form-submit">
      <Button type="primary" key="submit" htmlType="submit" disabled={disabled}>
        Einloggen
      </Button>
    </Form.Item>
  </Form>
)

export default LoginForm
