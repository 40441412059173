import { sendGetRequest, sendPostRequest } from './requests'

const baseUrl = '/api/drawings.php'

const DrawingService = {
  getAll: async () =>
    sendGetRequest(`${baseUrl}?action=getAll`).then(response =>
      response ? response.data.drawings : null
    ),

  get: async id =>
    sendGetRequest(`${baseUrl}?action=get&id=${id}`).then(response =>
      response ? response.data.drawing : null
    ),

  delete: async id => sendGetRequest(`${baseUrl}?action=delete&id=${id}`),

  start: async id => sendGetRequest(`${baseUrl}?action=start&id=${id}`),

  create: async (name, description) => {
    const payload = { action: 'create', name, description }
    return sendPostRequest(baseUrl, payload)
  },

  update: async (id, name, description) => {
    const payload = { action: 'update', id, name, description }
    return sendPostRequest(baseUrl, payload)
  }
}

export default DrawingService
