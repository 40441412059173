import { useHistory } from 'react-router-dom'
import { Button, Space, Tooltip } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

const TitleWithBack = ({ title, path = undefined }) => {
  const history = useHistory()

  return (
    <Space>
      <Tooltip title="zurück">
        <Button
          type="primary"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={() => (path ? history.push(path) : history.goBack())}
        />
      </Tooltip>
      <div>{title}</div>
    </Space>
  )
}

export default TitleWithBack
