import { sendPostRequest } from './requests'

const baseUrl = '/api/auth.php'

const AuthService = {
  login: async (username, password) => {
    const payload = { action: 'login', username, password }
    return sendPostRequest(baseUrl, payload).then(response => {
      if (response) {
        sessionStorage.setItem('token', response.data.token)
        return true
      }
      return false
    })
  },

  logout: () => sessionStorage.removeItem('token')
}

export default AuthService
