import { Space, Table, Tooltip } from 'antd'
import {DeleteOutlined, EditOutlined, MailOutlined, UserSwitchOutlined} from '@ant-design/icons'

import ActionButton from '../ActionButton'
import ActionButtonConfirm from '../ActionButtonConfirm'

const ParticipantsTable = ({ participants, handleEdit, handleDelete }) => {
  const nameForPartnerId = partnerId => {
    if (participants) {
      const partner = participants.find(p => p.id === partnerId)
      return partner ? partner.name : null
    }
    return null
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'partnerId',
      key: 'partnerId',
      render: (_, row) => (
        <Space>
          <MailStatus emailSuccess={row.emailSuccess} />
          <PartnerStatus partner={nameForPartnerId(row.partnerId)} />
        </Space>
      )
    },
    {
      title: 'Aktionen',
      key: 'action',
      render: (_, row) => (
        <Space size="small">
          <ActionButton
            title="Bearbeiten"
            icon={<EditOutlined />}
            onClick={() => handleEdit(row.id)}
          />
          <ActionButtonConfirm
            title="Löschen"
            description="Willst du die Auslosung wirklich löschen?"
            icon={<DeleteOutlined />}
            handleOk={() => handleDelete(row.id)}
          />
        </Space>
      )
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={participants}
      rowKey="id"
      pagination={false}
      scroll={{ y: 700, x: 1100 }}
    />
  )
}

const MailStatus = ({ emailSuccess }) => {
  const toolTip = emailSuccess
    ? 'Zulosung erfolgreich gesendet'
    : 'Zulosung noch nicht gesendet'
  return (
    <Tooltip title={toolTip}>
      <MailOutlined style={{ color: emailSuccess ? 'green' : 'red' }} />
    </Tooltip>
  )
}

const PartnerStatus = ({ partner }) => {
  const toolTip = partner
    ? `Partner erfolgreich zugelost: ${partner}`
    : 'Partner noch nicht zugelost'
  return (
    <Tooltip title={toolTip}>
      <UserSwitchOutlined style={{ color: partner ? 'green' : 'red' }} />
    </Tooltip>
  )
}

export default ParticipantsTable
