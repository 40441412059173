import openNotification from '../utils/notification'

const isDevMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

class NotAuthorized extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotAuthorized'
  }
}

const isAuthorized = response => {
  if (response.status === 401) {
    throw new NotAuthorized('Du hast hier keinen Zugang Alter!')
  }
  return response
}

const isSuccessful = async response => {
  if (!response.ok) {
    const error = await response.text()
    throw new Error(response.statusText + ': ' + error)
  }
  return response
}

const sendRequest = (uri, method, headers = undefined, body = undefined) => {
  return fetch(uri, {
    method,
    headers: {
      Accept: 'application/json',
      Authorization: authToken(),
      ...headers
    },
    body
  })
    .then(isAuthorized)
    .then(isSuccessful)
    .then(response => response.json())
    .catch(error => {
      if (error.name === 'NotAuthorized') {
        if (window.location.pathname !== '/login') {
          window.location = '/#/login'
        }
        openNotification('error', 'Kein Zugang', 'Du hast hier keine Macht!')
      } else {
        if (isDevMode) {
          console.error(error.message)
        }

        openNotification(
          'error',
          'Fehler',
          'Opps... Es ist ein Fehler aufgetreten!'
        )
      }
    })
}

const sendGetRequest = uri => {
  return sendRequest(uri, 'GET')
}

const sendPostRequest = (uri, data = null) => {
  const headers = { 'Content-Type': 'application/json' }
  const body = JSON.stringify(data)
  return sendRequest(uri, 'POST', headers, body)
}

const authToken = () => {
  return sessionStorage.getItem('token') || undefined
}

export { sendPostRequest, sendGetRequest }
