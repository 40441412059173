import { useHistory } from 'react-router-dom'
import { useState } from 'react'

import PageContent from 'components/PageContent'
import LoginForm from 'components/Forms/LoginForm'
import AuthService from 'services/AuthService'

const LoginPage = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const onSubmit = request => {
    setLoading(true)
    AuthService.login(request['username'], request['password']).then(
      success => {
        setLoading(false)
        if (success) {
          history.push('/admin')
        }
      }
    )
  }

  return (
    <PageContent title="Einloggen" size="small" loading={loading}>
      <LoginForm onSubmit={onSubmit} disabled={loading} />
    </PageContent>
  )
}

export default LoginPage
