import { Button, Form, Input } from 'antd'

const ParticipantForm = ({
  disabled,
  onSubmit,
  children,
  participant = null
}) => {
  const [form] = Form.useForm()
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Name:"
        name="name"
        initialValue={participant && participant.name}
        rules={[{ required: true, message: 'Bitte gebe einen Namen ein!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="E-Mail:"
        name="email"
        initialValue={participant && participant.email}
        rules={[
          {
            required: true,
            message: 'Bitte gebe eine E-Mail ein!'
          }
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Wunschliste:"
        name="hint"
        initialValue={participant && participant.hint}
      >
        <Input disabled={disabled} />
      </Form.Item>

      {children}

      <Form.Item className="form-submit">
        <Button
          disabled={disabled}
          type="primary"
          key="submit"
          htmlType="submit"
        >
          Ändern
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ParticipantForm
