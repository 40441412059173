import { Card } from 'antd'
import LoadingSpinner from './LoadingSpinner'

const PageContent = ({ size, children, loading = false, ...attrs }) => {
  const className = `content-card content-card-${size}`

  return (
    <Card className={className} bordered {...attrs}>
      {loading ? <LoadingSpinner /> : children}
    </Card>
  )
}
export default PageContent
