import { Switch, Route, useRouteMatch } from 'react-router-dom'
import RegisterStartPage from "./containers/RegisterStartPage";
import RegisterFormPage from "./containers/RegisterFormPage";

const RegisterPage = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:drawingId`}>
        <RegisterFormPage />
      </Route>
      <Route exact path={path}>
        <RegisterStartPage />
      </Route>
    </Switch>
  )
}

export default RegisterPage
