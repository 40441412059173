import {Route, Switch, useRouteMatch} from 'react-router-dom'
import ParticipantsAdminPage from "./containers/ParticipantsAdminPage";
import ParticipantEditPage from "./containers/ParticipantEditPage";
import DrawingsAdminPage from "./containers/DrawingsAdminPage";
import DrawingCreatePage from "./containers/DrawingCreatePage";
import DrawingEditPage from "./containers/DrawingEditPage";

const AdminPage = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/create`}>
        <DrawingCreatePage />
      </Route>
      <Route path={`${path}/drawing/:drawingId`}>
        <DrawingEditPage />
      </Route>
      <Route path={`${path}/participants/:drawingId`}>
        <ParticipantsAdminPage />
      </Route>
      <Route path={`${path}/participant/:participantId`}>
        <ParticipantEditPage />
      </Route>
      <Route exact path={path}>
        <DrawingsAdminPage />
      </Route>
    </Switch>
  )
}

export default AdminPage
