import { Button, Form, Input } from 'antd'

const EnterIdForm = ({ disabled, onSubmit }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Wichtel ID:"
        name="drawing_id"
        rules={[{ required: true, message: 'Bitte gebe die ID der Wichtelei ein!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item className="form-submit">
        <Button
          disabled={disabled}
          type="primary"
          key="submit"
          htmlType="submit"
        >
          Absenden
        </Button>
      </Form.Item>
    </Form>
  )
}

export default EnterIdForm
