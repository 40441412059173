import PageContent from 'components/PageContent'
import TitleWithBack from '../components/TitleWithBack'

const DisclaimerPage = () => (
  <PageContent title={<TitleWithBack title="Impressum" />} size="full">
    <h1>Impressum</h1>
    <p>
      Gunnar Busch
      <br />
      Chemnitzstraße 42
      <br />
      22767 Hamburg
      <br />
      Tel: +49 177 9573031
      <br />
      <a href="mailto:wichtler3000@gunnarbusch.de">
        wichtler3000@gunnarbusch.de
      </a>
    </p>
    <meta
      name="generator"
      content="Impressum-Generator der Kanzlei Hensche Rechtsanwälte"
    />
    <p>&nbsp;</p>
    <h1>Disclaimer - rechtliche Hinweise</h1>
    <p>
      <strong>Auskunfts- und Widerrufsrecht</strong>
    </p>
    <p>
      Sie haben jederzeit das Recht, sich unentgeltlich und unverz&#252;glich
      &#252;ber die zu Ihrer Person erhobenen Daten zu erkundigen. Ebenfalls
      k&#246;nnen Sie Ihre Zustimmung zur Verwendung Ihrer angegebenen
      pers&#246;nlichen Daten mit Wirkung f&#252;r die Zukunft widerrufen.
      Hierf&#252;r wenden Sie sich bitte an den im Impressum angegebenen
      Diensteanbieter.
    </p>
    <p>
      <strong>Datenschutz (allgemein)</strong>
    </p>
    <p>
      Beim Zugriff auf unsere Webseite werden automatisch allgemeine
      Informationen (sog. Server-Logfiles) erfasst. Diese beinhalten u.a. den
      von Ihnen verwendeten Webbrowser sowie Ihr Betriebssystem und Ihren
      Internet Service Provider. Diese Daten lassen keinerlei
      R&#252;ckschl&#252;sse auf Ihre Person zu und werden von uns statistisch
      ausgewertet, um unseren Internetauftritt technisch und inhaltlich zu
      verbessern. Das Erfassen dieser Informationen ist notwendig, um den Inhalt
      der Webseite korrekt ausliefern zu k&#246;nnen.
    </p>
    <p>
      Die Nutzung der Webseite ist grunds&#228;tzlich ohne Angabe
      personenbezogener Daten m&#246;glich. Soweit personenbezogene Daten
      (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden,
      erfolgt dies, soweit m&#246;glich, stets auf freiwilliger Basis. Diese
      Daten werden ohne Ihre ausdr&#252;ckliche Zustimmung nicht an Dritte
      weitergegeben.
    </p>
    <p>
      Sofern ein Vertragsverh&#228;ltnis begr&#252;ndet, inhaltlich ausgestaltet
      oder ge&#228;ndert werden soll oder Sie an uns eine Anfrage stellen,
      erheben und verwenden wir personenbezogene Daten von Ihnen, soweit dies zu
      diesem Zwecke erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten
      und nutzen personenbezogene Daten soweit dies erforderlich ist, um Ihnen
      die Inanspruchnahme des Webangebots zu erm&#246;glichen (Nutzungsdaten).
      S&#228;mtliche personenbezogenen Daten werden nur solange gespeichert wie
      dies f&#252;r den genannten Zweck (Bearbeitung Ihrer Anfrage oder
      Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer- und
      handelsrechtliche Aufbewahrungsfristen von uns ber&#252;cksichtigt. Auf
      Anordnung der zust&#228;ndigen Stellen m&#252;ssen wir im Einzelfall
      Auskunft &#252;ber diese Daten (Bestandsdaten) erteilen, soweit dies
      f&#252;r Zwecke der Strafverfolgung, zur Gefahrenabwehr, zur
      Erf&#252;llung der gesetzlichen Aufgaben der
      Verfassungsschutzbeh&#246;rden oder des Milit&#228;rischen
      Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen Eigentum
      erforderlich ist.
    </p>
    <p>
      Wir weisen ausdr&#252;cklich darauf hin, dass die Daten&#252;bertragung im
      Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitsl&#252;cken
      aufweisen kann. Vor dem Zugriff auf Daten kann nicht l&#252;ckenlos
      gesch&#252;tzt werden.
    </p>
    <p>
      Die Nutzung von im Rahmen der Impressumspflicht ver&#246;ffentlichten
      Kontaktdaten durch Dritte zur &#220;bersendung von nicht ausdr&#252;cklich
      angeforderter Werbung und Informationsmaterialien wird hiermit
      ausdr&#252;cklich untersagt. Ausgenommen hiervon sind bestehende
      Gesch&#228;ftsbeziehungen bzw. es liegt Ihnen eine entsprechende
      Einwilligung von uns vor.
    </p>
    <p>
      Die Anbieter und alle auf dieser Website genannten Dritten behalten sich
      ausdr&#252;cklich rechtliche Schritte im Falle der unverlangten Zusendung
      von Werbeinformationen vor. Gleiches gilt f&#252;r die kommerzielle
      Verwendung und Weitergabe der Daten.
    </p>
    <p>
      <strong>Datenschutz (Kommentarfunktion)</strong>
    </p>
    <p>
      Im Rahmen der Kommentarfunktion unseres Blogs erheben wir personenbezogene
      Daten (z. B. Name, E-Mail) im Rahmen Ihrer Kommentierung zu einem Beitrag
      nur in dem Umfang wie Sie ihn uns mitgeteilt haben. Bei der
      Ver&#246;ffentlichung eines Kommentars wird die von Ihnen angegebene
      Email-Adresse gespeichert, aber nicht ver&#246;ffentlicht. Ihr angegebener
      Name wird ver&#246;ffentlich. Sie k&#246;nnen auch ein Pseudonym benutzen.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>Disclaimer (Haftungsausschluss)</strong>
    </p>
    <p>
      <strong>1. Haftung f&#252;r Inhalte</strong>
    </p>
    <p>
      Als Diensteanbieter sind wir gem&#228;&#223; &#167; 7 Abs. 1 TMG f&#252;r
      eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
      verantwortlich. Nach &#167;&#167; 8 bis 10 TMG sind wir als
      Diensteanbieter jedoch nicht verpflichtet, &#252;bermittelte oder
      gespeicherte fremde Informationen zu &#252;berwachen oder nach
      Umst&#228;nden zu forschen, die auf eine rechtswidrige T&#228;tigkeit
      hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
      Informationen nach den allgemeinen Gesetzen bleiben hiervon
      unber&#252;hrt. Eine diesbez&#252;gliche Haftung ist jedoch erst ab dem
      Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&#246;glich. Bei
      Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
      Inhalte umgehend entfernen.
    </p>
    <p>
      <strong>2. Haftung f&#252;r Links</strong>
    </p>
    <p>
      Diese Website enth&#228;lt Links zu externen Webseiten Dritter, auf deren
      Inhalte kein Einfluss genommen werden kann. Deshalb kann f&#252;r diese
      fremden Inhalte auch keine Gew&#228;hr &#252;bernommen werden. F&#252;r
      die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
      Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
      Zeitpunkt der Verlinkung auf m&#246;gliche Rechtsverst&#246;&#223;e
      &#252;berpr&#252;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der
      Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der
      verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
      Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
      werden derartige Links umgehend von dieser Website auf die
      rechtsverletzende Site entfernen.
    </p>
    <p>
      <strong>3. Urheberrecht</strong>
    </p>
    <p>
      Die durch die Diensteanbieter, deren Mitarbeiter und beauftragte Dritte
      erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
      Urheberrecht. Die Vervielf&#228;ltigung, Bearbeitung, Verbreitung und jede
      Art der Verwertung au&#223;erhalb der Grenzen des Urheberrechtes
      bed&#252;rfen der vorherigen schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
      f&#252;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
      Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
      Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als
      solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
      derartige Inhalte umgehend entfernen.
    </p>
    <p>&nbsp;</p>
    <p>
      Dieses Impressum wurde mit Hilfe des{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://www.hensche.de/impressum-generator.html"
      >
        Impressum-Generators
      </a>{' '}
      von{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="http://www.hensche.de/Rechtsanwalt_Arbeitsrecht_Berlin.html"
      >
        HENSCHE Rechtsanw&auml;lte, Kanzlei f&uuml;r Arbeitsrecht
      </a>{' '}
      erstellt.
    </p>
  </PageContent>
)

export default DisclaimerPage
