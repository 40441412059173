import { Button, Form, Input } from 'antd'

const RegisterForm = ({ disabled, onSubmit }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Name:"
        name="name"
        rules={[{ required: true, message: 'Bitte gebe deinen Namen ein!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="E-Mail Adresse:"
        name="email"
        rules={[
          {
            required: true,
            message: 'Bitte gebe deine E-Mail ein!',
            type: 'email'
          }
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item label="Geheime Wunschliste:" name="hint">
        <Input
          disabled={disabled}
          placeholder="Gebe deinem Wichtel-Partner einen Tipp, wenn du willst :)"
        />
      </Form.Item>

      <Form.Item className="form-submit">
        <Button
          disabled={disabled}
          type="primary"
          key="submit"
          htmlType="submit"
        >
          Registrieren
        </Button>
      </Form.Item>
    </Form>
  )
}

export default RegisterForm
