import { Space, Table, Tooltip } from 'antd'
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  TeamOutlined
} from '@ant-design/icons'

import ActionButton from '../ActionButton'
import ActionButtonConfirm from '../ActionButtonConfirm'

const copyIdToClipBoard = id => {
  const url = `https://wichteln.buschlampe.de/#/register/${id}`
  window.prompt('Copy to clipboard: Ctrl+C, Enter', url)
}

const DrawingsTable = ({
  drawings,
  handleEdit,
  handleParticipants,
  handleStart,
  handleDelete
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: id => (
        <Space>
          {id}
          <ActionButton
            title="Link kopieren"
            icon={<LinkOutlined />}
            onClick={() => copyIdToClipBoard(id)}
          />
        </Space>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Beschreibung',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Erstellt',
      dataIndex: 'timestamp',
      key: 'timestamp'
    },
    {
      title: 'Status',
      dataIndex: 'partnerId',
      key: 'partnerId',
      render: (_, row) => (
        <Space>
          <DrawingStatus isDrawn={row.isDrawn} />
        </Space>
      )
    },
    {
      title: 'Aktionen',
      key: 'action',
      render: (_, row) => (
        <Space size="small">
          <ActionButton
            title="Bearbeiten"
            icon={<EditOutlined />}
            onClick={() => handleEdit(row.id)}
          />
          <ActionButton
            title="Teilnehmer"
            icon={<TeamOutlined />}
            onClick={() => handleParticipants(row.id)}
          />
          <ActionButtonConfirm
            title="Auslosung starten"
            description="Willst du die Auslosung wirklich starten?"
            icon={<PlayCircleOutlined />}
            handleOk={() => handleStart(row.id)}
          />
          <ActionButtonConfirm
            title="Löschen"
            description="Willst du die Auslosung wirklich löschen?"
            icon={<DeleteOutlined />}
            handleOk={() => handleDelete(row.id)}
          />
        </Space>
      )
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={drawings}
      rowKey="id"
      pagination={false}
      scroll={{ y: 700, x: 1100 }}
    />
  )
}

const DrawingStatus = ({ isDrawn }) => {
  const toolTip = isDrawn
    ? 'Zulosung erfolgreich gestartet'
    : 'Zulosung noch nicht gestartet'
  return (
    <Tooltip title={toolTip}>
      <CheckCircleOutlined style={{ color: isDrawn ? 'green' : 'red' }} />
    </Tooltip>
  )
}

export default DrawingsTable
