import { Select } from 'antd'
const { Option } = Select

const ParticipantsSelection = ({
  participants,
  onSelect,
  onClear,
  disabled,
  selected
}) => {
  const options =
    participants &&
    participants.map(participant => (
      <Option key={participant.id} value={participant.id.toString()}>
        {participant.name}
      </Option>
    ))

  return participants ? (
    <Select
      showSearch
      allowClear
      style={{ width: 200 }}
      defaultValue={selected}
      placeholder="Teilnehmer auswählen"
      optionFilterProp="children"
      disabled={disabled}
      onSelect={onSelect}
      onClear={onClear}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  ) : null
}

export default ParticipantsSelection
