import { useHistory } from 'react-router-dom'

import PageContent from 'components/PageContent'
import EnterIdForm from 'components/Forms/EnterIdForm'

const RegisterStartPage = () => {
  const history = useHistory()

  const onSubmit = request => {
    const drawingId = request['drawing_id']
    history.push(`/register/${drawingId}`)
  }

  return (
    <PageContent title="Bei Wichtelei anmelden" size="small">
      <p>
        Um bei der Wichtei teilzunehmen gebe zunächst die Wichtel ID ein, die du
        von jemanden bekommen hast, um dich bei der Wichtelei anzumelden.
      </p>
      <EnterIdForm onSubmit={onSubmit} />
    </PageContent>
  )
}

export default RegisterStartPage
