import './App.less'
import './snow.scss'

import { useState } from 'react'
import { Layout, Button, Drawer, Space } from 'antd'
import { GiftOutlined, LoginOutlined, MenuOutlined } from '@ant-design/icons'
import {
  HashRouter as Router,
  Switch,
  Route,
  useHistory
} from 'react-router-dom'

import AdminPage from 'pages/AdminPage'
import StartPage from 'pages/StartPage'
import LoginPage from 'pages/LoginPage'
import RegisterPage from 'pages/RegisterPage'
import DisclaimerPage from 'pages/DisclaimerPage'

const { Header, Content, Footer } = Layout

function App() {
  return (
    <Router>
      <SnowWrapper>
        <Layout className="layout">
          <PageHeader />
          <PageContent>
            <Switch>
              <Route exact path="/login">
                <LoginPage />
              </Route>
              <Route exact path="/disclaimer">
                <DisclaimerPage />
              </Route>
              <Route path="/admin">
                <AdminPage />
              </Route>
              <Route path="/register">
                <RegisterPage />
              </Route>
              <Route path="/">
                <StartPage />
              </Route>
            </Switch>
          </PageContent>
          <PageFooter />
        </Layout>
      </SnowWrapper>
    </Router>
  )
}

function SnowWrapper({ children }) {
  return (
    <div className="snow-wrapper">
      <div className="snow layer3 a" />
      <div className="snow layer3" />
      {children}
      <div className="snow layer2 a" />
      <div className="snow layer2" />
    </div>
  )
}

const PageHeader = () => (
  <Header className="pageHeader">
    <a className="logo" href="/">
      Wichtler 3000 !!!
    </a>

    <div className="menu">
      <PageMenu />
    </div>
  </Header>
)

const PageMenu = () => {
  const history = useHistory()
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }
  const onClose = () => {
    setVisible(false)
  }

  const open = path => {
    history.push(path)
    setVisible(false)
  }

  return (
    <>
      <Button icon={<MenuOutlined />} onClick={showDrawer} />
      <Drawer
        title="Menü"
        placement="right"
        onClose={onClose}
        visible={visible}
        closable
      >
        <Space direction="vertical">
          <Button
            type="text"
            size="large"
            icon={<GiftOutlined />}
            onClick={() => open('/register')}
          >
            Teilnehmen
          </Button>
          <Button
            type="text"
            size="large"
            icon={<LoginOutlined />}
            onClick={() => open('/admin')}
          >
            Einloggen
          </Button>
        </Space>
      </Drawer>
    </>
  )
}

const PageContent = ({ children }) => (
  <Content className="background">
    <div className="content">{children}</div>
    <div className="background-attribution">
      Image/Bild: <a href="http://www.freepik.com">Designed by Freepik</a>
    </div>
  </Content>
)

const PageFooter = () => {
  const history = useHistory()

  return (
    <Footer className="pageFooter">
      ©2020{' '}
      <a href="https://github.com/buschbros/" target="_blank" rel="noreferrer">
        Busch Bros &#127795;
      </a>
      &nbsp;|&nbsp;
      <Button type="link" className="disclaimer-link" onClick={() => history.push('/disclaimer')}>
        Impressum
      </Button>
    </Footer>
  )
}

export default App
