import PageContent from 'components/PageContent'
import GiphyWrapper from '../components/GiphyWrapper'

const StartPage = () => (
  <PageContent
    title="Willkommen!"
    size="small"
    cover={
      <GiphyWrapper
        id="HBMCmtsPEUShG"
        href="https://giphy.com/gifs/christmas-ugly-sweater-HBMCmtsPEUShG"
      />
    }
  >
    <p>
      Wichteln mit dem <span className="logo">Wichtler3000 !!!</span>
    </p>
    <p>Nur über Einladung und nur so lange der Vorrat reicht!</p>
    <p>
      Zu Risiken und Nebenwirkungen lesen Sie die Packungsbeilage und fragen Sie
      Ihren Arzt oder Apotheker!
    </p>
  </PageContent>
)

export default StartPage
