import { Button, Form, Input } from 'antd'

const DrawingForm = ({
  disabled,
  onSubmit,
  valueName = '',
  valueDescription = ''
}) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label="Name für die Wichtelung:"
        name="name"
        initialValue={valueName}
        rules={[{ required: true, message: 'Bitte gebe einen Namen ein!' }]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Kurze Beschreibung der Wichtelung:"
        name="description"
        initialValue={valueDescription}
        rules={[
          {
            required: true,
            message: 'Bitte gebe eine Beschreibung ein!'
          }
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item className="form-submit">
        <Button
          disabled={disabled}
          type="primary"
          key="submit"
          htmlType="submit"
        >
          Fertig
        </Button>
      </Form.Item>
    </Form>
  )
}

export default DrawingForm
