import { sendGetRequest, sendPostRequest } from './requests'

const baseUrl = '/api/participants.php'

const ParticipantService = {
  getAllForDrawing: async drawingId =>
    sendGetRequest(`${baseUrl}?action=getForDrawing&id=${drawingId}`).then(
      response => (response ? response.data.participants : null)
    ),

  getAllForList: async drawingId =>
    sendGetRequest(`${baseUrl}?action=getForList&id=${drawingId}`).then(
      response => (response ? response.data.participants : null)
    ),

  get: async id =>
    sendGetRequest(`/api/participants.php?action=get&id=${id}`).then(response =>
      response ? response.data.participant : null
    ),

  delete: async id => sendGetRequest(`${baseUrl}?action=delete&id=${id}`),

  create: async (drawingId, name, email, hint) => {
    const payload = {
      action: 'create',
      drawing_id: drawingId,
      name,
      email,
      hint
    }
    return sendPostRequest(baseUrl, payload)
  },

  update: async (id, name, email, hint, notPartnerId) => {
    const payload = {
      action: 'update',
      id,
      name,
      email,
      hint,
      notPartnerId
    }
    return sendPostRequest(baseUrl, payload)
  }
}

export default ParticipantService
