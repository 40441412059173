import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import PageContent from 'components/PageContent'
import TitleWithBack from 'components/TitleWithBack'
import DrawingForm from 'components/Forms/DrawingForm'
import DrawingService from 'services/DrawingService'
import openNotification from 'utils/notification'

const DrawingCreatePage = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const onSubmit = request => {
    setLoading(true)
    DrawingService.create(request['name'], request['description'])
      .then(() => {
        openNotification(
          'success',
          'Erstellt!',
          'Die Wichtelei wurde erfolgreich erstellt!'
        )
      })
      .then(() => history.push('/admin'))
  }

  return (
    <PageContent
      title={<TitleWithBack title="Wichtelung erstellen" />}
      size="small"
      loading={loading}
    >
      <DrawingForm disabled={loading} onSubmit={onSubmit} />
    </PageContent>
  )
}

export default DrawingCreatePage
