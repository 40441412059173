import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Space } from 'antd'

import PageContent from 'components/PageContent'
import TitleWithBack from 'components/TitleWithBack'
import RegisterForm from 'components/Forms/RegisterForm'
import DrawingService from 'services/DrawingService'
import ParticipantService from 'services/ParticipantService'
import openNotification from 'utils/notification'
import GiphyWrapper from '../../../components/GiphyWrapper'

const RegisterFormPage = () => {
  const { drawingId } = useParams()

  const [drawing, setDrawing] = useState(null)
  const [isRegistered, setIsRegistered] = useState(false)

  useEffect(() => {
    DrawingService.get(drawingId).then(drawing => {
      if (drawing && drawing.isDrawn) {
        openNotification(
          'alert',
          'Zu spät...',
          'Die Wichtelei ist leider schon beendet!'
        )
      } else {
        setDrawing(drawing)
      }
    })
  }, [drawingId])

  const onSubmit = request => {
    ParticipantService.create(
      drawingId,
      request['name'],
      request['email'],
      request['hint']
    ).then(() => setIsRegistered(true))
  }

  if (!drawing) {
    return <NotFoundMessage drawingId={drawingId} />
  }

  return isRegistered ? (
    <SuccessMessage drawing={drawing} />
  ) : (
    <RegisterFormContainer drawing={drawing} onSubmit={onSubmit} />
  )
}

const NotFoundMessage = ({ drawingId }) => (
  <PageContent
    title={<TitleWithBack title="Wichtelung nicht gefunden!" />}
    size="small"
  >
    <Space direction="vertical">
      <Alert
        type="warning"
        message={`Wichtelei mit der ID '${drawingId}' wurde leider nicht gefunden`}
        showIcon
      />
    </Space>
  </PageContent>
)

const SuccessMessage = ({ drawing }) => (
  <PageContent
    title="Erfolgreich angemeldet!"
    size="small"
    cover={
      <GiphyWrapper
        id={'xUOxeXhZlq0eVhHJde'}
        href="https://giphy.com/gifs/zdf-german-det-xUOxeXhZlq0eVhHJde"
      />
    }
  >
    <Alert
      type="success"
      showIcon
      message={`Du wurdest erfolgreich zur Wichtelei '${
        drawing.name
      }' angemeldet. In den nächsten Tagen erhältst du eine E-Mail mit deinem Wichtelpartner. Viel Spaß!`}
    />
  </PageContent>
)

const RegisterFormContainer = ({ drawing, onSubmit }) => (
  <PageContent title="Anmelden zum Wichteln" size="small">
    <div className="register-description">{drawing.description}</div>
    <RegisterForm disabled={drawing && drawing.isDrawn} onSubmit={onSubmit} />
  </PageContent>
)

export default RegisterFormPage
