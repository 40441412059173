import { useState } from 'react'
import { Modal } from 'antd'

import ActionButton from "./ActionButton";

const ActionButtonConfirm = ({ title, icon, description, handleOk }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <ActionButton
        title={title}
        icon={icon}
        onClick={() => setVisible(true)}
      />
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={() => setVisible(false)}
      >
        <p>{description}</p>
      </Modal>
    </>
  )
}

export default ActionButtonConfirm
